.sessionViewMapContainer {
    display: flex;
    flex-direction: column;
    height:100vh;
    box-sizing: border-box;
  }
  
  .sessionViewMapContainer .ol-map {
    position: absolute;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  