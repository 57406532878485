.navbar {
    flex-shrink: 0;
    /* Do not allow the navbar to shrink */
    background-color: #393040;
    z-index: 2;
    color: white;
}

.navbar a.navbar-brand {
    color: white;
}

.navbar a.navbar-brand:hover {
    color: salmon;
}

.navbar a.navbar-brand:focus {
    color: salmon;
}